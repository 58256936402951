import { type FC, useState } from "react"
import styled from "styled-components"

import CommunityProfileAvatar from "@forento/shared/components/CommunityProfileAvatar"
import { type CommunityPostComment } from "@forento/shared/models/community"
import { toDateTimeString } from "@forento/shared/utilities/date"

import Button from "~/themes/original/components/Button"
import { DeleteIcon } from "~/themes/original/components/Icon"
import ProfilePopup from "~/themes/original/components/ProfilePopup"
import { toRelativeDateString } from "~/themes/original/utilities/date"
import routes from "~/utilities/routes"
import { dangerColor, lightTextColor } from "~/utilities/styles"

type Props = { comment: CommunityPostComment; onDelete?(): Promise<void> }

const Comment: FC<Props> = ({ comment, onDelete }) => {
	const [isDeleting, setDeleting] = useState(false)
	const [authorElement, setAuthorElement] = useState<HTMLDivElement | null>(null)

	const handleDelete =
		onDelete !== undefined
			? async () => {
					setDeleting(true)
					try {
						await onDelete()
					} finally {
						setDeleting(false)
					}
				}
			: undefined

	return (
		<Container>
			<ProfilePopup targetElement={authorElement} profileId={comment.profile.id} />
			<Button onClick={routes.community.profile(comment.profile.id)}>
				<Avatar profile={comment.profile} ref={setAuthorElement} />
			</Button>
			<Content>
				<Author>
					{comment.profile.firstName} {comment.profile.lastName}
				</Author>
				<Text>{comment.text}</Text>
			</Content>
			<Date title={toDateTimeString(comment.createDate)}>{toRelativeDateString(comment.createDate)}</Date>
			{handleDelete !== undefined && (
				<Delete onClick={handleDelete} isLoading={isDeleting} replaceOnLoading>
					<DeleteIcon />
				</Delete>
			)}
		</Container>
	)
}

const Container = styled.div`
	display: grid;
	grid-template-areas: "avatar content delete" "avatar date .";
	row-gap: 4px;
	column-gap: 12px;
`

const Avatar = styled(CommunityProfileAvatar).attrs({ size: 32 })`
	grid-area: avatar;
`

const Content = styled.div`
	grid-area: content;
	max-width: 525px;
	background: #ffffff;
	border-radius: 8px;
	padding: 4px 8px;
`

const Author = styled.p`
	font-weight: 500;
	font-size: 16px;
	color: #151d48;
`

const Text = styled.p`
	font-size: 14px;
	color: ${lightTextColor};
`

const Date = styled.p`
	grid-area: date;
	color: ${lightTextColor};
	font-size: 12px;
`

const Delete = styled(Button)`
	grid-area: delete;
	align-self: center;
	color: ${dangerColor};
	width: 24px;
	height: 24px;
`

export default Comment

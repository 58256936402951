import { useEffect } from "react"
import { Navigate, useParams } from "react-router"
import styled from "styled-components"

import VideoPlayer from "@forento/shared/components/VideoPlayer"

import { PurchaseSuccessModalContextProvider } from "~/contexts/PurchaseSuccessModalContext"
import { useUser } from "~/contexts/UserContext"
import Button from "~/themes/school/components/Button"
import Hero from "~/themes/school/components/Hero"
import Layout from "~/themes/school/components/Layout"
import PartialLoadingPage from "~/themes/school/components/PartialLoadingPage"
import PurchaseItem from "~/themes/school/components/PurchaseItem"
import { fonts } from "~/themes/school/styles"
import { useTranslation } from "~/translations"
import routes from "~/utilities/routes"
import { swr } from "~/utilities/trpc"

const VideoPage: React.FC = () => {
	const { videoId } = useParams()
	const user = useUser()
	const t = useTranslation()

	const { data: video, error, mutate } = swr.video.getPersonalized.useSWR({ videoId: Number(videoId) })

	useEffect(() => {
		mutate()
	}, [user.user, mutate])

	if (video === null) return <Navigate to={routes.video.index()} />

	return (
		<Layout>
			{error ? (
				<p>{t("failedToLoad")}</p>
			) : video === undefined ? (
				<PartialLoadingPage />
			) : (
				<PurchaseSuccessModalContextProvider
					content={{
						type: "video",
						isUnlocked: video.status === "unlocked",
						payPalOrderId:
							video.status === "unlocked" &&
							video.unlockedCause === "purchased" &&
							video.order.type === "paypal"
								? video.order.id
								: null,
					}}
				>
					{video.status === "unlocked" ? (
						<Container>
							<StyledVideoPlayer video={video} />
							<Title>{video.title}</Title>
							{video.longDescription && (
								<Description dangerouslySetInnerHTML={{ __html: video.longDescription.html }} />
							)}
						</Container>
					) : (
						<Hero
							title={video.title}
							htmlDescription={video.longDescription?.html}
							thumbnail={
								video.thumbnailFilePath !== null ? (
									<Thumbnail src={video.thumbnailFilePath} />
								) : undefined
							}
							callToAction={
								video.status === "membership-required" ? (
									<CallToAction>
										<p>{t("product.onlyForMembers")}</p>
										<Button variant="primary" onClick={routes.membership.index()}>
											{t("membership.title")}
										</Button>
									</CallToAction>
								) : video.price !== null ? (
									<PurchaseItem
										item={{ type: "video", id: video.id, price: video.price }}
										onPurchase={mutate}
									/>
								) : video.isSignInRequired ? (
									<CallToAction>
										<p>{t("product.onlyForUsers")}</p>
										<ButtonsRow>
											<Button variant="primary" onClick={routes.user.signin()}>
												{t("user.signIn.title")}
											</Button>
											<Button variant="primary" onClick={routes.user.signup()}>
												{t("user.signUp.title")}
											</Button>
										</ButtonsRow>
									</CallToAction>
								) : null
							}
						/>
					)}
				</PurchaseSuccessModalContextProvider>
			)}
		</Layout>
	)
}

const Container = styled.div`
	max-width: 1400px;
	background-color: white;
	box-shadow: 0px 4px 30px 0px rgba(223, 232, 255, 0.25);
	padding: 24px;
`

const StyledVideoPlayer = styled(VideoPlayer)`
	background-color: #999;
	margin-bottom: 16px;
`

const Title = styled.h1`
	font-family: ${fonts.quicksand};
	font-size: 28px;
	font-weight: bold;
	margin-bottom: 12px;
`

const Description = styled.div`
	font-size: 16px;
`

const Thumbnail = styled.img.attrs({ alt: "Video thumbnail" })`
	width: 100%;
	height: 100%;
	object-fit: contain;
`

const CallToAction = styled.div`
	max-width: 400px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
	text-align: center;
`

const ButtonsRow = styled.div`
	display: flex;
	gap: 16px;

	@media (max-width: 499px) {
		flex-direction: column;
	}
`

export default VideoPage

import {
	INSERT_ORDERED_LIST_COMMAND,
	INSERT_UNORDERED_LIST_COMMAND,
	ListNode,
	REMOVE_LIST_COMMAND,
} from "@lexical/list"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $createHeadingNode, HeadingNode } from "@lexical/rich-text"
import { $getSelectionStyleValueForProperty, $isAtNodeEnd, $patchStyleText, $setBlocksType } from "@lexical/selection"
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils"
import {
	$createParagraphNode,
	$getSelection,
	$isParagraphNode,
	$isRangeSelection,
	$isTextNode,
	COMMAND_PRIORITY_LOW,
	type ElementNode,
	FORMAT_TEXT_COMMAND,
	type RangeSelection,
	SELECTION_CHANGE_COMMAND,
	type TextFormatType,
	type TextNode,
} from "lexical"
import { type FC, useCallback, useEffect, useRef, useState } from "react"
import { HexColorInput, HexColorPicker } from "react-colorful"
import { createPortal } from "react-dom"
import styled, { css } from "styled-components"

import { toNullIfEmpty } from "../../utilities/string"
import Tooltip from "../Tooltip"

function setToolbarPosition(targetRect: DOMRect | null, floatingElem: HTMLElement): void {
	const verticalGap = 10
	const horizontalOffset = 5
	const scrollerElem = document.body.parentElement

	if (targetRect === null || !scrollerElem) {
		floatingElem.style.opacity = "0"
		floatingElem.style.transform = "translate(-10000px, -10000px)"
		return
	}

	const floatingElemRect = floatingElem.getBoundingClientRect()
	const anchorElementRect = document.body.getBoundingClientRect()
	const editorScrollerRect = scrollerElem.getBoundingClientRect()

	let top = targetRect.top - floatingElemRect.height - verticalGap
	let left = targetRect.left - horizontalOffset

	if (top < editorScrollerRect.top) {
		top += floatingElemRect.height + targetRect.height + verticalGap * 2
	}

	if (left + floatingElemRect.width > editorScrollerRect.right) {
		left = editorScrollerRect.right - floatingElemRect.width - horizontalOffset
	}

	top -= anchorElementRect.top
	left -= anchorElementRect.left

	floatingElem.style.opacity = "1"
	floatingElem.style.transform = `translate(${left}px, ${top}px)`
}

function getSelectedNode(selection: RangeSelection): TextNode | ElementNode {
	const anchorNode = selection.anchor.getNode()
	const focusNode = selection.focus.getNode()
	if (anchorNode === focusNode) return anchorNode

	if (selection.isBackward()) {
		return $isAtNodeEnd(selection.focus) ? anchorNode : focusNode
	} else {
		return $isAtNodeEnd(selection.anchor) ? anchorNode : focusNode
	}
}

type Props = { features: { headings: boolean; colors: boolean } }
const LexicalToolbarPlugin: FC<Props> = ({ features }) => {
	const [editor] = useLexicalComposerContext()

	const containerRef = useRef<HTMLDivElement | null>(null)

	const [isText, setText] = useState(false)
	const [isColorPickerOpen, setColorPickerOpen] = useState(false)

	const [isHeading, setHeading] = useState(false)
	const [color, setColor] = useState<string | null>(null)
	const [isBold, setBold] = useState(false)
	const [isItalic, setItalic] = useState(false)
	const [isUnderline, setUnderline] = useState(false)
	const [isStrikethrough, setStrikethrough] = useState(false)
	const [isSubscript, setSubscript] = useState(false)
	const [isSuperscript, setSuperscript] = useState(false)
	const [isCode, setCode] = useState(false)
	const [listType, setListType] = useState<"bullet" | "number" | null>(null)

	const update = useCallback(() => {
		editor.getEditorState().read(() => {
			if (editor.isComposing()) return

			const selection = $getSelection()
			const nativeSelection = window.getSelection()
			const rootElement = editor.getRootElement()

			if (
				nativeSelection !== null &&
				(!$isRangeSelection(selection) ||
					rootElement === null ||
					!rootElement.contains(nativeSelection.anchorNode))
			) {
				setText(false)
				return
			}

			if (!$isRangeSelection(selection)) return

			const node = getSelectedNode(selection)
			if (selection.getTextContent() !== "") {
				setText($isTextNode(node) || $isParagraphNode(node))
			} else {
				setText(false)
			}

			const rawTextContent = selection.getTextContent().replace(/\n/g, "")
			if (!selection.isCollapsed() && rawTextContent === "") {
				setText(false)
				return
			}

			setBold(selection.hasFormat("bold"))
			setItalic(selection.hasFormat("italic"))
			setUnderline(selection.hasFormat("underline"))
			setStrikethrough(selection.hasFormat("strikethrough"))
			setSubscript(selection.hasFormat("subscript"))
			setSuperscript(selection.hasFormat("superscript"))
			setCode(selection.hasFormat("code"))
			setColor(toNullIfEmpty($getSelectionStyleValueForProperty(selection, "color")))

			const parentHeading = $getNearestNodeOfType<HeadingNode>(node, HeadingNode)
			setHeading(node instanceof HeadingNode || parentHeading !== null)

			const parentList = $getNearestNodeOfType<ListNode>(node, ListNode)
			const listNodeToUse = node instanceof ListNode ? node : parentList
			const listType = listNodeToUse?.getListType() ?? null
			setListType(listType !== "check" ? listType : null)
		})
	}, [editor])

	useEffect(() => {
		const abortController = new AbortController()

		document.addEventListener("selectionchange", update, { signal: abortController.signal })

		return () => {
			abortController.abort()
		}
	}, [update])

	useEffect(() => {
		return mergeRegister(
			editor.registerUpdateListener(() => {
				update()
			}),
			editor.registerRootListener(() => {
				if (editor.getRootElement() === null) {
					setText(false)
				}
			}),
		)
	}, [editor, update])

	useEffect(() => {
		const abortController = new AbortController()

		document.addEventListener(
			"mousemove",
			event => {
				if (!containerRef.current) return

				if (event.buttons === 1 || event.buttons === 3) {
					if (containerRef.current.style.pointerEvents !== "none") {
						const x = event.clientX
						const y = event.clientY
						const elementUnderMouse = document.elementFromPoint(x, y)

						if (!containerRef.current.contains(elementUnderMouse)) {
							// Mouse is not over the target element => not a normal click, but probably a drag
							containerRef.current.style.pointerEvents = "none"
						}
					}
				}
			},
			{ signal: abortController.signal },
		)
		document.addEventListener(
			"mouseup",
			() => {
				if (!containerRef.current) return

				if (containerRef.current.style.pointerEvents !== "auto") {
					containerRef.current.style.pointerEvents = "auto"
				}
			},
			{ signal: abortController.signal },
		)

		return () => {
			abortController.abort()
		}
	}, [containerRef])

	const $updateToolbarPosition = useCallback(() => {
		if (containerRef.current === null) return

		const selection = $getSelection()

		const nativeSelection = window.getSelection()

		const rootElement = editor.getRootElement()
		if (
			selection !== null &&
			nativeSelection !== null &&
			!nativeSelection.isCollapsed &&
			rootElement !== null &&
			rootElement.contains(nativeSelection.anchorNode)
		) {
			let rect
			if (nativeSelection.anchorNode === rootElement) {
				let inner = rootElement
				while (inner.firstElementChild !== null) {
					inner = inner.firstElementChild as HTMLElement
				}
				rect = inner.getBoundingClientRect()
			} else {
				rect = nativeSelection.getRangeAt(0).getBoundingClientRect()
			}

			setToolbarPosition(rect, containerRef.current)
		}
	}, [editor])

	useEffect(() => {
		const abortController = new AbortController()

		function updateToolbarPosition() {
			editor.getEditorState().read(() => {
				$updateToolbarPosition()
			})
		}

		window.addEventListener("resize", updateToolbarPosition, { signal: abortController.signal })

		const scrollerElement = document.body.parentElement
		if (scrollerElement) {
			scrollerElement.addEventListener("scroll", updateToolbarPosition, { signal: abortController.signal })
		}

		return () => {
			abortController.abort()
		}
	}, [editor, $updateToolbarPosition])

	useEffect(() => {
		editor.getEditorState().read(() => {
			$updateToolbarPosition()
		})
		return mergeRegister(
			editor.registerUpdateListener(({ editorState }) => {
				editorState.read(() => {
					$updateToolbarPosition()
				})
			}),

			editor.registerCommand(
				SELECTION_CHANGE_COMMAND,
				() => {
					$updateToolbarPosition()
					return false
				},
				COMMAND_PRIORITY_LOW,
			),
		)
	}, [editor, $updateToolbarPosition])

	useEffect(() => {
		if (!isText) {
			setColorPickerOpen(false)
		}
	}, [isText])

	function formatText(formatType: TextFormatType) {
		editor.dispatchCommand(FORMAT_TEXT_COMMAND, formatType)
	}

	function toggleHeading() {
		editor.update(() => {
			const selection = $getSelection()
			if (isHeading) {
				$setBlocksType(selection, () => $createParagraphNode())
			} else {
				$setBlocksType(selection, () => $createHeadingNode("h2"))
			}
		})
	}

	if (!isText || !editor.isEditable()) return null

	return createPortal(
		<Container ref={containerRef}>
			{features.headings && (
				<Tooltip tooltip="Heading">
					<Option $isActive={isHeading} onClick={toggleHeading}>
						<OptionIcon viewBox="0 -960 960 960">
							<path d="M120-280v-400h80v160h160v-160h80v400h-80v-160H200v160h-80Zm400 0v-160q0-33 23.5-56.5T600-520h160v-80H520v-80h240q33 0 56.5 23.5T840-600v80q0 33-23.5 56.5T760-440H600v80h240v80H520Z" />
						</OptionIcon>
					</Option>
				</Tooltip>
			)}
			{features.colors && (
				<ColorOptionContainer>
					<Tooltip tooltip="Text color">
						<Option
							$isActive={isColorPickerOpen}
							onClick={() => {
								setColorPickerOpen(current => !current)
							}}
						>
							<OptionIcon viewBox="0 -960 960 960" fill={color ?? undefined}>
								<path d="M80 0v-160h800V0H80Zm140-280 210-560h100l210 560h-96l-50-144H368l-52 144h-96Zm176-224h168l-82-232h-4l-82 232Z" />
							</OptionIcon>
						</Option>
					</Tooltip>
					{isColorPickerOpen && (
						<ColorPickerContainer>
							<ColorPicker
								color={color ?? undefined}
								onChange={color => {
									editor.update(() => {
										const selection = $getSelection()
										if (selection) {
											$patchStyleText(selection, { color })
										}
									})
								}}
							/>
							<ColorInput color={color ?? undefined} onChange={setColor} prefixed />
							<ResetColorButton
								onClick={() => {
									editor.update(() => {
										const selection = $getSelection()
										if (selection) {
											$patchStyleText(selection, { color: null })
										}
									})
								}}
							>
								Reset
							</ResetColorButton>
						</ColorPickerContainer>
					)}
				</ColorOptionContainer>
			)}
			<Tooltip tooltip="Bold">
				<Option $isActive={isBold} onClick={() => formatText("bold")}>
					<OptionIcon viewBox="0 -960 960 960">
						<path d="M272-200v-560h221q65 0 120 40t55 111q0 51-23 78.5T602-491q25 11 55.5 41t30.5 90q0 89-65 124.5T501-200H272Zm121-112h104q48 0 58.5-24.5T566-372q0-11-10.5-35.5T494-432H393v120Zm0-228h93q33 0 48-17t15-38q0-24-17-39t-44-15h-95v109Z" />
					</OptionIcon>
				</Option>
			</Tooltip>
			<Tooltip tooltip="Italic">
				<Option $isActive={isItalic} onClick={() => formatText("italic")}>
					<OptionIcon viewBox="0 -960 960 960">
						<path d="M200-200v-100h160l120-360H320v-100h400v100H580L460-300h140v100H200Z" />
					</OptionIcon>
				</Option>
			</Tooltip>
			<Tooltip tooltip="Underline">
				<Option $isActive={isUnderline} onClick={() => formatText("underline")}>
					<OptionIcon viewBox="0 -960 960 960">
						<path d="M200-120v-80h560v80H200Zm280-160q-101 0-157-63t-56-167v-330h103v336q0 56 28 91t82 35q54 0 82-35t28-91v-336h103v330q0 104-56 167t-157 63Z" />
					</OptionIcon>
				</Option>
			</Tooltip>
			<Tooltip tooltip="Strikethrough">
				<Option $isActive={isStrikethrough} onClick={() => formatText("strikethrough")}>
					<OptionIcon viewBox="0 -960 960 960">
						<path d="M80-400v-80h800v80H80Zm340-160v-120H200v-120h560v120H540v120H420Zm0 400v-160h120v160H420Z" />
					</OptionIcon>
				</Option>
			</Tooltip>
			<Tooltip tooltip="Subscript">
				<Option $isActive={isSubscript} onClick={() => formatText("subscript")}>
					<OptionIcon viewBox="0 -960 960 960">
						<path d="M760-160v-80q0-17 11.5-28.5T800-280h80v-40H760v-40h120q17 0 28.5 11.5T920-320v40q0 17-11.5 28.5T880-240h-80v40h120v40H760Zm-525-80 185-291-172-269h106l124 200h4l123-200h107L539-531l186 291H618L482-457h-4L342-240H235Z" />
					</OptionIcon>
				</Option>
			</Tooltip>
			<Tooltip tooltip="Superscript">
				<Option $isActive={isSuperscript} onClick={() => formatText("superscript")}>
					<OptionIcon viewBox="0 -960 960 960">
						<path d="M760-600v-80q0-17 11.5-28.5T800-720h80v-40H760v-40h120q17 0 28.5 11.5T920-760v40q0 17-11.5 28.5T880-680h-80v40h120v40H760ZM235-160l185-291-172-269h106l124 200h4l123-200h107L539-451l186 291H618L482-377h-4L342-160H235Z" />
					</OptionIcon>
				</Option>
			</Tooltip>
			<Tooltip tooltip="Code">
				<Option $isActive={isCode} onClick={() => formatText("code")}>
					<OptionIcon viewBox="0 -960 960 960">
						<path d="M320-240 80-480l240-240 57 57-184 184 183 183-56 56Zm320 0-57-57 184-184-183-183 56-56 240 240-240 240Z" />
					</OptionIcon>
				</Option>
			</Tooltip>
			<Tooltip tooltip="Bullet list">
				<Option
					$isActive={listType === "bullet"}
					onClick={() => {
						if (listType === "bullet") {
							editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
						} else {
							editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
						}
					}}
				>
					<OptionIcon viewBox="0 -960 960 960">
						<path d="M280-600v-80h560v80H280Zm0 160v-80h560v80H280Zm0 160v-80h560v80H280ZM160-600q-17 0-28.5-11.5T120-640q0-17 11.5-28.5T160-680q17 0 28.5 11.5T200-640q0 17-11.5 28.5T160-600Zm0 160q-17 0-28.5-11.5T120-480q0-17 11.5-28.5T160-520q17 0 28.5 11.5T200-480q0 17-11.5 28.5T160-440Zm0 160q-17 0-28.5-11.5T120-320q0-17 11.5-28.5T160-360q17 0 28.5 11.5T200-320q0 17-11.5 28.5T160-280Z" />
					</OptionIcon>
				</Option>
			</Tooltip>
			<Tooltip tooltip="Numbered list">
				<Option
					$isActive={listType === "number"}
					onClick={() => {
						if (listType === "number") {
							editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
						} else {
							editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
						}
					}}
				>
					<OptionIcon viewBox="0 -960 960 960">
						<path d="M120-80v-60h100v-30h-60v-60h60v-30H120v-60h120q17 0 28.5 11.5T280-280v40q0 17-11.5 28.5T240-200q17 0 28.5 11.5T280-160v40q0 17-11.5 28.5T240-80H120Zm0-280v-110q0-17 11.5-28.5T160-510h60v-30H120v-60h120q17 0 28.5 11.5T280-560v70q0 17-11.5 28.5T240-450h-60v30h100v60H120Zm60-280v-180h-60v-60h120v240h-60Zm180 440v-80h480v80H360Zm0-240v-80h480v80H360Zm0-240v-80h480v80H360Z" />
					</OptionIcon>
				</Option>
			</Tooltip>
		</Container>,
		document.body,
	)
}

const Container = styled.div`
	display: flex;
	background: #fff;
	padding: 4px;
	vertical-align: middle;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10000;
	opacity: 0;
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
	border-radius: 8px;
	transition: opacity 0.5s;
	display: flex;
	flex-direction: row;
	gap: 2px;
`

const Option = styled.button.attrs({ type: "button" })<{ $isActive: boolean }>`
	appearance: none;
	text-decoration: none;
	border: none;
	font-weight: normal;
	color: black;
	text-align: left;
	margin: 0;
	display: flex;
	background: none;
	border-radius: 10px;
	padding: 8px;
	cursor: pointer;
	vertical-align: middle;

	${props =>
		props.$isActive &&
		css`
			background-color: rgba(223, 232, 250, 0.3);
		`}

	&:hover {
		background-color: #eee;
	}
`

const ColorOptionContainer = styled.div`
	position: relative;
`

const OptionIcon = styled.svg.attrs({ xmlns: "http://www.w3.org/2000/svg" })`
	background-size: contain;
	height: 18px;
	width: 18px;
	display: flex;
	opacity: 0.6;
`

const ColorPickerContainer = styled.div`
	position: absolute;
	z-index: 10001;
	bottom: -4px;
	left: calc(100% + 8px);
	background-color: white;
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	padding: 8px;
	display: grid;
	grid-template-areas: "picker input" "picker reset" "picker .";
	gap: 8px;
`

const ColorPicker = styled(HexColorPicker)`
	grid-area: picker;
`

const ColorInput = styled(HexColorInput)`
	grid-area: input;
	font-size: 16px;
	color: black;
	background-color: white;
	border: 1px solid #d0d5dd;
	border-radius: 8px;
	box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
	padding: 8px 12px;

	&::placeholder {
		color: #6d6d6d;
	}
`

const ResetColorButton = styled.button`
	grid-area: reset;
	appearance: none;
	font-size: 16px;
	color: black;
	background-color: white;
	border: 1px solid #d0d5dd;
	background-color: transparent;
	border-radius: 8px;
	box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
	padding: 8px 12px;
	cursor: pointer;
`

export default LexicalToolbarPlugin

import { type FC, useState } from "react"
import styled from "styled-components"

import CommunityProfileAvatar from "@forento/shared/components/CommunityProfileAvatar"
import { type PublicCommunityProfile } from "@forento/shared/models/community"

import ProfilePopup from "~/themes/school/components/ProfilePopup"

const Like: FC<{ profile: PublicCommunityProfile }> = ({ profile }) => {
	const [authorElement, setAuthorElement] = useState<HTMLElement | null>(null)

	return (
		<Container key={profile.id}>
			<ProfilePopup targetElement={authorElement} profileId={profile.id} />
			<CommunityProfileAvatar size={32} profile={profile} ref={setAuthorElement} />
			<p key={profile.id}>
				{profile.firstName} {profile.lastName}
			</p>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`

export default Like

import { type PlatformCommunity } from "@forento/shared/models/platform"
import { type PlatformUser } from "@forento/shared/models/user"

export function hasAccessToViewCommunity(user: PlatformUser, community: PlatformCommunity): boolean {
	if (user.isCreator) return true

	const hasDefaultAccess = community.hasAccess.default ?? false
	const hasMembershipAccess = Boolean(user.membership && community.hasAccess.membership[user.membership.id])

	return hasDefaultAccess || hasMembershipAccess
}

import { $getRoot, CLEAR_EDITOR_COMMAND, type LexicalEditor, type EditorState } from "lexical"

export function isEditorStateEmpty(state: EditorState): boolean {
	return state.read<boolean>(() => {
		const root = $getRoot()
		const firstChild = root.getFirstChild()
		if (!firstChild) return true
		return firstChild.getTextContentSize() === 0 && root.getChildrenSize() === 1
	})
}

export function loadEditorState(editor: LexicalEditor, data: string | null): void {
	editor.setEditable(false)
	if (data !== null) {
		editor.setEditorState(editor.parseEditorState(data))
	} else {
		editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined)
	}
	requestAnimationFrame(() => editor.setEditable(true))
}

export function exportEditorState(state: EditorState): string | null {
	if (isEditorStateEmpty(state)) return null
	return JSON.stringify(state.toJSON())
}

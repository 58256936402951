import { useId, useMemo, useState } from "react"
import type React from "react"
import styled, { css } from "styled-components"

import LexicalEditor, { type LexicalEditorType } from "@forento/shared/components/LexicalEditor"
import { exportEditorState, loadEditorState } from "@forento/shared/utilities/lexical"

import { backgroundColor, lightTextColor } from "~/themes/school/styles"

interface InputFieldProps {
	label?: string
	value: string
	onChange?: (value: string) => void
	onEnter?: () => void
	placeholder?: string
	inputType?: "text" | "number" | "email" | "password"
	autoComplete?: "given-name" | "family-name" | "email" | "current-password" | "new-password"
	disabled?: boolean
	className?: string
}

interface InputTextAreaProps {
	value: string
	onChange: (value: string) => void
	placeholder?: string
	className?: string
}

const InputField: React.FC<InputFieldProps> = ({
	label,
	value,
	onChange,
	onEnter,
	placeholder,
	inputType = "text",
	autoComplete,
	disabled,
	className,
}) => {
	const inputId = useId()

	return (
		<TextFieldContainer className={className}>
			<Input
				id={inputId}
				type={inputType}
				value={value}
				onChange={e => onChange?.(e.target.value)}
				onKeyDown={e => e.key === "Enter" && onEnter?.()}
				// We need to always send a placeholder for :placeholder-shown to work
				placeholder={placeholder ?? ""}
				autoComplete={autoComplete}
				disabled={disabled}
				data-sentry-mask={inputType === "password" ? true : undefined}
			/>
			{label !== undefined && <Label htmlFor={inputId}>{label}</Label>}
		</TextFieldContainer>
	)
}

export const InputTextArea: React.FC<InputTextAreaProps> = ({ value, onChange, placeholder, className }) => (
	<TextArea
		value={value}
		onChange={e => onChange(e.target.value)}
		// We need to always send a placeholder for :placeholder-shown to work
		placeholder={placeholder ?? ""}
		className={className}
	/>
)

export function useRichTextArea({ label }: { label?: string }) {
	const id = useId()
	const [editorRef, setEditorRef] = useState<LexicalEditorType>()
	const [isEmpty, setEmpty] = useState(true)

	const value = useMemo(
		() => ({
			element: (
				<TextAreaContainer onClick={() => editorRef?.focus()}>
					<RichTextArea id={id} $hideLabel={!isEmpty}>
						<LexicalEditor
							editorRef={setEditorRef}
							onEmptyChange={setEmpty}
							features={{ headings: false, colors: false }}
						/>
					</RichTextArea>
					{label !== undefined && <Label htmlFor={id}>{label}</Label>}
				</TextAreaContainer>
			),
			set(data: string | null): void {
				if (!editorRef) return
				loadEditorState(editorRef, data)
			},
			exportEditorState(): string | null {
				if (!editorRef) return null
				return exportEditorState(editorRef.getEditorState())
			},
		}),
		[editorRef, id, isEmpty, label],
	)

	return value
}

const TextFieldContainer = styled.div`
	height: 36px;
	flex: 1 0 auto;
	min-width: 0;
	position: relative;
`

const TextAreaContainer = styled.div`
	flex: 1 0 auto;
	min-width: 0;
	position: relative;
`

export const InputFieldElement = TextFieldContainer

const Label = styled.label`
	position: absolute;
	top: ${36 / 2}px;
	left: 24px;
	font-size: 14px;
	user-select: none;
	transform: translateY(-50%);
	transition:
		transform 0.15s ease-out,
		font-size 0.15s ease-out;
`

const inputStyles = css`
	width: 100%;
	height: 100%;
	font-size: 14px;
	background-color: white;
	border-radius: 10px;
	color: ${lightTextColor};
	border: 1px solid ${backgroundColor};
	padding: 0 24px;

	&::placeholder {
		color: #999;
	}

	&:disabled {
		background-color: #f0f0ef;
	}
`

const hideLabelCss = css`
	& + ${Label} {
		font-size: 11px;
		transform: translateY(calc(-100% - 20px));
	}
`

const Input = styled.input`
	${inputStyles}
	line-height: 1;

	&:focus,
	&:not(:placeholder-shown),
	&:-webkit-autofill {
		${hideLabelCss}
	}
`

export const InputLabel = styled.label`
	display: block;
	font-size: 11px;
	margin: 0 0 2px 24px;
`

const TextArea = styled.textarea`
	${inputStyles}
	min-height: 104px; // Twice the height of an input field
	padding-top: ${(36 - 14) / 2}px;
	padding-bottom: ${(36 - 14) / 2}px;
`

const RichTextArea = styled.div<{ $hideLabel: boolean }>`
	${inputStyles}
	min-height: 104px; // Twice the height of an input field
	display: flex;
	flex-direction: column;
	padding-top: ${(36 - 14) / 2}px;
	padding-bottom: ${(36 - 14) / 2}px;
	cursor: text;

	&:focus-within {
		${hideLabelCss}
	}

	${props => props.$hideLabel && hideLabelCss}
`

export default InputField

const zeroDecimalCurrencies = new Set([
	"BIF",
	"CLP",
	"DJF",
	"GNF",
	"JPY",
	"KMF",
	"KRW",
	"MGA",
	"PYG",
	"RWF",
	"UGX",
	"VND",
	"VUV",
	"XAF",
	"XOF",
	"XPF",
])

export function isZeroFractionCurrency(currency: string) {
	return zeroDecimalCurrencies.has(currency.toUpperCase())
}

export function toPriceString({ amount, currency }: { amount: number; currency: string }) {
	if (isZeroFractionCurrency(currency)) {
		return Math.round(amount).toLocaleString("en-US", { style: "currency", currency })
	}

	return amount.toLocaleString("en-US", {
		style: "currency",
		currency,
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	})
}

export function toPriceStringUSD(amount: number) {
	return toPriceString({ amount, currency: "USD" })
}

export function toPercentString(value: number) {
	return value.toLocaleString("en-US", { style: "percent" })
}

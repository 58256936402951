import styled from "styled-components"

import { usePlatform } from "~/contexts/PlatformContext"
import Button from "~/themes/school/components/Button"
import { fonts } from "~/themes/school/styles"

interface MobileHeaderProps {
	leftAction?: {
		icon: React.ComponentType
		onClick: string | (() => void)
	}
	className?: string
}

const MobileHeader: React.FC<MobileHeaderProps> = ({ leftAction, className }) => {
	const platform = usePlatform().platform

	return (
		<Container className={className}>
			{leftAction ? (
				<SideContent as={Button} onClick={leftAction.onClick}>
					<StyledIcon as={leftAction.icon} />
				</SideContent>
			) : (
				<SideContent />
			)}
			{platform.logo !== null ? <Logo alt="Logo" src={platform.logo.filePath} /> : <Brand>{platform.name}</Brand>}
			<SideContent />
		</Container>
	)
}

const Container = styled.header`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (min-width: 1000px) {
		display: none;
	}
`

const SideContent = styled.div`
	width: 24px;
	height: 24px;
	padding: 18px;
	box-sizing: content-box;

	&:not(:empty) {
		background-color: white;
		box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.06);
	}
`

const StyledIcon = styled.div`
	width: 100%;
	height: 100%;
`

const Brand = styled.p`
	font-family: ${fonts.quicksand};
	font-size: 20px;
	font-weight: bold;
`

const Logo = styled.img`
	height: 36px;
`

export default MobileHeader

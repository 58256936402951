import { type FC } from "react"
import styled from "styled-components"

import { usePlatform } from "~/contexts/PlatformContext"

const Logo: FC<{ className?: string }> = ({ className }) => {
	const platform = usePlatform().platform

	return platform.logo !== null ? (
		platform.logo.isIcon ? (
			platform.homeLink !== null ? (
				<Container className={className} as="a" href={platform.homeLink}>
					<Icon alt="Icon" src={platform.logo.filePath} />
					<Brand>{platform.name}</Brand>
				</Container>
			) : (
				<Container className={className}>
					<Icon alt="Icon" src={platform.logo.filePath} />
					<Brand>{platform.name}</Brand>
				</Container>
			)
		) : platform.homeLink !== null ? (
			<a href={platform.homeLink}>
				<Image alt="Logo" src={platform.logo.filePath} className={className} />
			</a>
		) : (
			<Image alt="Logo" src={platform.logo.filePath} className={className} />
		)
	) : platform.homeLink !== null ? (
		<Brand className={className} as="a" href={platform.homeLink}>
			{platform.name}
		</Brand>
	) : (
		<Brand className={className}>{platform.name}</Brand>
	)
}

const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 22px;
	text-decoration: none;
`

const Brand = styled.div`
	font-size: 26px;
	font-weight: 600;
	color: black;
	text-decoration: none;
`

const Icon = styled.img`
	height: 42px;
`

const Image = styled.img`
	height: 52px;
`

export default Logo

import { type ComponentType } from "react"
import { Navigate } from "react-router"

import { usePlatform } from "~/contexts/PlatformContext"
import { useUser } from "~/contexts/UserContext"
import { hasAccessToViewCommunity } from "~/utilities/community"
import { getHomeRoute } from "~/utilities/routes"

export default function withCommunityAuthCheck<T extends JSX.IntrinsicAttributes>(Component: ComponentType<T>) {
	return (props: T) => {
		const user = useUser().user!
		const platform = usePlatform().platform

		if (platform.community === null || !hasAccessToViewCommunity(user, platform.community)) {
			return <Navigate to={getHomeRoute(platform)} />
		}

		return <Component {...props} />
	}
}

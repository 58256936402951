import { type FC } from "react"

import { toPriceString } from "@forento/shared/utilities/currency"

import Card, { Cards } from "~/themes/original/components/Card"
import Layout from "~/themes/original/components/Layout"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import routes from "~/utilities/routes"
import { swr } from "~/utilities/trpc"

const SessionsPage: FC = () => {
	const { data: sessions, error } = swr.privateSession.list.useSWR()

	return (
		<Layout>
			{error ? (
				<p>Failed to load sessions.</p>
			) : sessions === undefined ? (
				<PartialLoadingPage />
			) : (
				<Cards>
					{sessions.map(session => (
						<Card
							key={session.id}
							title={session.title}
							description={session.shortDescription ?? undefined}
							footer={{
								text: session.price
									? toPriceString(session.price)
									: session.membershipIds.length > 0
										? "Membership"
										: "Free",
								isBold: true,
							}}
							onClick={routes.session.detail(session.id)}
						/>
					))}
				</Cards>
			)}
		</Layout>
	)
}

export default SessionsPage

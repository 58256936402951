import { type FC, useState } from "react"
import { Helmet } from "react-helmet-async"
import { Navigate, useParams } from "react-router"
import styled from "styled-components"

import { parseNumber } from "@forento/shared/utilities/number"

import { type CourseDetail, useCourseDetail } from "~/api/course"
import { PurchaseSuccessModalContextProvider } from "~/contexts/PurchaseSuccessModalContext"
import useEditorJsDisplay from "~/hooks/useEditorJsDisplay"
import Button from "~/themes/school/components/Button"
import FullLoadingPage from "~/themes/school/components/FullLoadingPage"
import Hero from "~/themes/school/components/Hero"
import { ArrowIcon } from "~/themes/school/components/Icon"
import Layout from "~/themes/school/components/Layout"
import { PageBreadcrumb } from "~/themes/school/components/PageHeader"
import PurchaseItem from "~/themes/school/components/PurchaseItem"
import { ControlledTabs } from "~/themes/school/components/Tabs"
import useTabs from "~/themes/school/pages/course/CourseDetailPage/useTabs"
import { useTranslation } from "~/translations"
import routes from "~/utilities/routes"

const CourseDetailPage: FC = () => {
	const courseId = parseNumber(useParams().courseId!)
	const [data, reload] = useCourseDetail(courseId ?? -1)
	const t = useTranslation()

	return (
		<Layout>
			<PageBreadcrumb
				path={[{ title: t("course.title"), link: routes.course.index() }]}
				title={data.status === "success" ? data.course.title : "..."}
			/>
			{(() => {
				switch (data.status) {
					case "error":
						return t("failedToLoad")
					case "loading":
						return <FullLoadingPage />
					case "not-found":
						return <Navigate to={routes.course.index()} />
					case "success": {
						return <Content data={data} reload={reload} />
					}
				}
			})()}
		</Layout>
	)
}

type ContentProps = { data: Extract<CourseDetail, { status: "success" }>; reload(): Promise<unknown> }
const Content: FC<ContentProps> = ({ data, reload }) => {
	const [longDescriptionElement, setLongDescriptionElement] = useState<HTMLElement | null>(null)
	const tabs = useTabs(data.course, reload, setLongDescriptionElement)
	const t = useTranslation()

	useEditorJsDisplay(longDescriptionElement, data.course.longDescription)

	const callToAction: JSX.Element = (() => {
		switch (data.button.variant) {
			case "start":
				return (
					<Button variant="primary" onClick={routes.course.player(data.course.id)}>
						<CallToActionIcon as={ArrowIcon} />
						{t("course.start")}
					</Button>
				)
			case "continue":
				return (
					<Button variant="primary" onClick={routes.course.player(data.course.id)}>
						<CallToActionIcon as={ArrowIcon} />
						{t("course.continue")}
					</Button>
				)
			case "purchase":
				return (
					<PurchaseItem
						item={{ type: "course", id: data.course.id, price: data.button.price }}
						onPurchase={reload}
					/>
				)
			case "membership":
				return (
					<CallToAction>
						<p>{t("product.onlyForMembers")}</p>
						<Button variant="primary" onClick={routes.membership.index()}>
							{t("membership.title")}
						</Button>
					</CallToAction>
				)
		}
	})()

	return (
		<PurchaseSuccessModalContextProvider
			content={{
				type: "course",
				id: data.course.id,
				isUnlocked: data.course.status === "unlocked",
				payPalOrderId:
					data.course.status === "unlocked" &&
					data.course.unlockedCause === "purchased" &&
					data.course.order.type === "paypal"
						? data.course.order.id
						: null,
			}}
		>
			<Helmet>
				<title>{data.title}</title>
				<meta name="description" content={data.course.shortDescription} />
				<meta name="og:title" content={data.title} />
				<meta name="og:description" content={data.course.shortDescription} />
				{data.course.thumbnailFilePath !== null && (
					<meta name="og:image" content={data.course.thumbnailFilePath} />
				)}
			</Helmet>
			<Hero
				thumbnail={data.course.thumbnailFilePath && <Thumbnail src={data.course.thumbnailFilePath} />}
				title={data.course.title}
				description={data.course.shortDescription}
				callToAction={callToAction}
			/>
			<MoreContent>
				<ControlledTabs tabs={tabs} />
			</MoreContent>
		</PurchaseSuccessModalContextProvider>
	)
}

const Thumbnail = styled.img.attrs({ alt: "Course thumbnail" })`
	width: 100%;
	height: 100%;
	object-fit: cover;
`

const CallToAction = styled.div`
	max-width: 300px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
	text-align: center;
`

const CallToActionIcon = styled.div`
	width: 16px;
	height: 16px;
`

const MoreContent = styled.div`
	background-color: white;
	box-shadow: 0px 4px 30px 0px rgba(223, 232, 255, 0.25);
	padding: 24px;

	@media (min-width: 1000px) {
		padding: 48px 86px;
	}
`

export default CourseDetailPage

import { useParams } from "react-router"
import styled from "styled-components"

import { parseNumber } from "@forento/shared/utilities/number"

import Layout from "~/themes/original/components/Layout"
import PartialLoadingPage from "~/themes/original/components/PartialLoadingPage"
import Post from "~/themes/original/components/Post"
import { swr } from "~/utilities/trpc"

import Profile from "./Profile"

const ProfilePage: React.FC = () => {
	const profileId = parseNumber(useParams().profileId ?? "") ?? -1

	const profile = swr.community.getProfile.useSWR(profileId)
	const posts = swr.community.listPersonalizedPostsByProfile.useSWR(profileId)

	return (
		<Layout>
			<Content>
				{profile.error || posts.error ? (
					"Failed to load profile."
				) : profile.data === undefined || posts.data === undefined ? (
					<PartialLoadingPage />
				) : profile.data === null ? (
					"Profile was not found."
				) : (
					<>
						<Profile profile={profile.data} />
						<Posts>
							{[...posts.data]
								.sort((a, b) => b.createDate.getTime() - a.createDate.getTime())
								.map(x => (
									<Post key={x.id} post={x} onChange={posts.mutate} showChannel />
								))}
						</Posts>
					</>
				)}
			</Content>
		</Layout>
	)
}

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 42px;

	@media (min-width: 1000px) {
		flex-direction: row;
		align-items: flex-start;
	}
`

const Posts = styled.div`
	flex: 2 0 0;
	display: flex;
	flex-direction: column;
	gap: 32px;
`

export default ProfilePage

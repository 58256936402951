import { type FC, useState } from "react"
import { Navigate, useParams } from "react-router"
import styled from "styled-components"

import { parseNumber } from "@forento/shared/utilities/number"

import { PurchaseSuccessModalContextProvider } from "~/contexts/PurchaseSuccessModalContext"
import Button from "~/themes/school/components/Button"
import Hero from "~/themes/school/components/Hero"
import { ArrowIcon } from "~/themes/school/components/Icon"
import Layout from "~/themes/school/components/Layout"
import Modal, { ModalButtons, ModalTitle } from "~/themes/school/components/Modal"
import { PageBreadcrumb } from "~/themes/school/components/PageHeader"
import PartialLoadingPage from "~/themes/school/components/PartialLoadingPage"
import PurchaseItem from "~/themes/school/components/PurchaseItem"
import { useTranslation } from "~/translations"
import routes from "~/utilities/routes"
import { swr } from "~/utilities/trpc"

import Thumbnail from "./Thumbnail"

const SessionPage: FC = () => {
	const sessionId = parseNumber(useParams().sessionId!)
	const t = useTranslation()

	const [isModalOpen, setModalOpen] = useState(false)

	const { data: session, error, mutate } = swr.privateSession.getPersonalized.useSWR(sessionId ?? -1)

	if (session === null) return <Navigate to={routes.session.index()} />

	const redemotionInstructions = session?.status === "unlocked" ? session.redemptionInstructions : null
	const redemptionLink = session?.status === "unlocked" ? session.redemptionLink : null

	return (
		<Layout>
			<Modal isOpen={isModalOpen}>
				<ModalTitle>{t("session.redeem")}</ModalTitle>
				<p>{redemotionInstructions}</p>
				<ModalButtons>
					{redemptionLink !== null ? (
						<>
							<Button variant="secondary" onClick={() => setModalOpen(false)}>
								{t("cancel")}
							</Button>
							<Button variant="primary" onClick={redemptionLink} newTab>
								{t("session.redeem")}
							</Button>
						</>
					) : (
						<Button variant="primary" onClick={() => setModalOpen(false)}>
							{t("close")}
						</Button>
					)}
				</ModalButtons>
			</Modal>
			{error ? (
				<p>{t("failedToLoad")}</p>
			) : session === undefined ? (
				<PartialLoadingPage />
			) : (
				<PurchaseSuccessModalContextProvider
					content={{
						type: "session",
						isUnlocked: session.status === "unlocked",
						payPalOrderId:
							session.status === "unlocked" &&
							session.unlockedCause === "purchased" &&
							session.order.type === "paypal"
								? session.order.id
								: null,
					}}
				>
					<PageBreadcrumb
						path={[{ title: t("session.title"), link: routes.session.index() }]}
						title={session.title}
					/>
					<Hero
						title={session.title}
						htmlDescription={session.longDescription?.html}
						thumbnail={<Thumbnail />}
						callToAction={
							session.status === "paywall" ? (
								<PurchaseItem
									item={{ type: "session", id: session.id, price: session.price }}
									onPurchase={mutate}
								/>
							) : session.status === "sign-in-required" ? (
								<CallToAction>
									<p>{t("product.onlyForUsers")}</p>
									<ButtonsRow>
										<Button variant="primary" onClick={routes.user.signin()}>
											{t("user.signIn.title")}
										</Button>
										<Button variant="primary" onClick={routes.user.signup()}>
											{t("user.signUp.title")}
										</Button>
									</ButtonsRow>
								</CallToAction>
							) : session.status === "membership-required" ? (
								<CallToAction>
									<p>{t("product.onlyForMembers")}</p>
									<ButtonsRow>
										<Button variant="primary" onClick={routes.membership.index()}>
											{t("membership.title")}
										</Button>
									</ButtonsRow>
								</CallToAction>
							) : session.status === "unlocked" ? (
								session.redemptionInstructions !== null ? (
									<Button variant="primary" onClick={() => setModalOpen(true)}>
										<CallToActionIcon as={ArrowIcon} />
										{t("session.redeem")}
									</Button>
								) : session.redemptionLink !== null ? (
									<Button variant="primary" onClick={session.redemptionLink} newTab>
										<CallToActionIcon as={ArrowIcon} />
										{t("session.redeem")}
									</Button>
								) : null
							) : null
						}
					/>
				</PurchaseSuccessModalContextProvider>
			)}
		</Layout>
	)
}

const CallToAction = styled.div`
	max-width: 400px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
	text-align: center;
`

const ButtonsRow = styled.div`
	display: flex;
	gap: 16px;

	@media (max-width: 499px) {
		flex-direction: column;
	}
`

const CallToActionIcon = styled.div`
	width: 16px;
	height: 16px;
`

export default SessionPage

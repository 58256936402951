import { useMemo, useState } from "react"
import styled, { css } from "styled-components"
import tinycolor from "tinycolor2"
import Values from "values.js"

import AspectRatio from "@forento/shared/components/AspectRatio"
import { toPriceString } from "@forento/shared/utilities/currency"

import Button from "~/themes/school/components/Button"
import Card, { CardHeader, CardSticker, CardText, CardTitle, Cards } from "~/themes/school/components/Card"
import ContentSearch from "~/themes/school/components/ContentSearch"
import Layout from "~/themes/school/components/Layout"
import PageHeader, { PageTitle } from "~/themes/school/components/PageHeader"
import PartialLoadingPage from "~/themes/school/components/PartialLoadingPage"
import { backgroundColor } from "~/themes/school/styles"
import { useTranslation } from "~/translations"
import routes from "~/utilities/routes"
import { swr } from "~/utilities/trpc"

const VideosPage: React.FC = () => {
	const t = useTranslation()

	const [searchItems, setSearchItems] = useState<number[]>()
	const [selectedTag, setSelectedTag] = useState<number>()

	const tags = swr.video.listTags.useSWR()
	const videos = swr.video.listPersonalized.useSWR()

	const contentSearchItems = useMemo(
		() => videos.data?.map(video => ({ id: video.id, value: video.title })) ?? [],
		[videos.data],
	)

	return (
		<Layout>
			<PageHeader>
				<PageTitle>{t("video.title")}</PageTitle>
				<ContentSearch items={contentSearchItems} onItemsToShowChange={setSearchItems} />
			</PageHeader>
			{tags.error || videos.error ? (
				<p>{t("failedToLoad")}</p>
			) : tags.data === undefined || videos.data === undefined ? (
				<PartialLoadingPage />
			) : (
				<>
					{tags.data.length > 0 && (
						<Tags>
							{tags.data.map(tag => (
								<Tag
									key={tag.id}
									$isSelected={selectedTag === tag.id}
									onClick={() => setSelectedTag(current => (current !== tag.id ? tag.id : undefined))}
								>
									{tag.label}
								</Tag>
							))}
							{selectedTag !== undefined && (
								<Tag $isSelected onClick={() => setSelectedTag(undefined)}>
									{t("clearFilter")}
								</Tag>
							)}
						</Tags>
					)}
					<Cards>
						{(selectedTag !== undefined
							? videos.data.filter(video => video.tags.some(tag => tag.id === selectedTag))
							: videos.data
						)
							.filter(x => searchItems?.includes(x.id) ?? true)
							.sort((a, b) => (searchItems ? searchItems.indexOf(b.id) - searchItems.indexOf(a.id) : 0))
							.map(video => (
								<Card key={video.id} as={Button} onClick={routes.video.detail(video.id)}>
									<CardHeader>
										<CardTitle>{video.title}</CardTitle>
										<CardSticker>
											<Price>
												{video.price
													? toPriceString(video.price)
													: video.membershipIds.length > 0
														? t("membership.title")
														: t("product.priceFree")}
											</Price>
										</CardSticker>
									</CardHeader>
									{video.thumbnailFilePath !== null && (
										<StyledAspectRatio aspectRatio={16 / 9} width={{ value: 100, unit: "percent" }}>
											<Thumbnail src={video.thumbnailFilePath} />
										</StyledAspectRatio>
									)}
									{video.shortDescription && <CardText>{video.shortDescription}</CardText>}
								</Card>
							))}
					</Cards>
				</>
			)}
		</Layout>
	)
}

const Tags = styled.div`
	display: flex;
	gap: 16px;
	flex-wrap: wrap;
	margin-bottom: 16px;

	@media (max-width: 999px) {
		gap: 8px;
		margin-left: 16px;
		margin-right: 16px;
	}
`

const Tag = styled(Button)<{ $isSelected: boolean }>(props => {
	const color = props.$isSelected
		? props.theme.primaryColor
		: new Values(props.theme.primaryColor).tint(50).hexString()

	return css`
		padding: 12px 24px;
		border-radius: 40px;
		background-color: ${color};
		color: ${tinycolor(color).isDark() ? "white" : "black"};
		line-height: 1;
	`
})

const StyledAspectRatio = styled(AspectRatio)`
	background-color: ${backgroundColor};
	overflow: hidden;
`

const Thumbnail = styled.img.attrs({ alt: "Course thumbnail" })`
	width: 100%;
	height: 100%;
	object-fit: cover;
`

const Price = styled.div`
	font-weight: bold;
	font-size: 16px;
	color: #ffa412;
`

export default VideosPage

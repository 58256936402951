import { type FC, useState } from "react"
import { useLocation } from "react-router"
import styled from "styled-components"
import tinycolor from "tinycolor2"

import Tooltip from "@forento/shared/components/Tooltip"
import { type PurchaseableType } from "@forento/shared/models/product"
import { toPriceString } from "@forento/shared/utilities/currency"

import { usePlatform } from "~/contexts/PlatformContext"
import { useUser } from "~/contexts/UserContext"
import { isColorDark } from "~/utilities/color"
import routes from "~/utilities/routes"
import trpc from "~/utilities/trpc"

import Button from "../Button"
import PayPalButtons from "./PayPalButtons"

interface Props {
	item: {
		id: number
		type: PurchaseableType
		price: { amount: number; currency: string }
	}
	onPurchase(): void
}

const PurchaseItem: FC<Props> = ({ item, onPurchase }) => {
	const user = useUser().user
	const platform = usePlatform().platform
	const location = useLocation()

	const [isStripePurchasing, setStripePurchasing] = useState(false)

	if (platform.paymentGateway === null) return null

	const handleStripePurchase = async () => {
		setStripePurchasing(true)
		try {
			const { redirectUrl } = await trpc.payment.stripe.createOrder.mutate({ item })
			window.location.href = redirectUrl
		} catch (error) {
			console.error(error)
			setStripePurchasing(false)
		}
	}

	const buyLabel =
		item.type === "event" ? `Buy ticket — ${toPriceString(item.price)}` : `Buy — ${toPriceString(item.price)}`

	return user === null ? (
		<Button variant="primary" onClick={`${routes.user.signup()}?next=${encodeURIComponent(location.pathname)}`}>
			{buyLabel}
		</Button>
	) : !platform.paymentGateway.isEnabled ? (
		<Tooltip tooltip="Payments are not enabled">
			<Button variant="primary" isDisabled>
				{buyLabel}
			</Button>
		</Tooltip>
	) : platform.paymentGateway.type === "stripe" ? (
		<Button variant="primary" onClick={handleStripePurchase} isLoading={isStripePurchasing}>
			{buyLabel}
		</Button>
	) : platform.paymentGateway.type === "paypal" ? (
		<Container>
			<Price>{toPriceString(item.price)}</Price>
			<PayPalButtons onPurchase={onPurchase} item={item} />
		</Container>
	) : null
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
`

const Price = styled.div`
	width: 100%;
	color: ${props => (isColorDark(props.theme.primaryColor) ? "#f9fafb" : "black")};
	background-color: ${props => tinycolor(props.theme.primaryColor).lighten(8).toString()};
	font-weight: 600;
	font-size: 20px;
	text-align: center;
	padding: 4px 8px;
	border-radius: 8px;
`

export default PurchaseItem

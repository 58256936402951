import Fuse from "fuse.js"
import { type FC, useEffect, useMemo, useState } from "react"
import styled from "styled-components"

import { useTranslation } from "~/translations"

import Button from "./Button"
import InputField from "./InputField"

type Props = { items: { id: number; value: string }[]; onItemsToShowChange(items: number[] | undefined): void }

const ContentSearch: FC<Props> = ({ items, onItemsToShowChange }) => {
	const t = useTranslation()

	const [value, setValue] = useState("")

	const searcher = useMemo(
		() =>
			new Fuse(
				items.map(item => item.value),
				{ includeScore: true },
			),
		[items],
	)

	useEffect(() => {
		if (value.trim().length === 0) {
			onItemsToShowChange(undefined)
		} else {
			onItemsToShowChange(
				searcher
					.search(value)
					.sort((a, b) => (b.score ?? 0) - (a.score ?? 0))
					.map(item => items[item.refIndex].id),
			)
		}
	}, [items, onItemsToShowChange, searcher, value])

	return (
		<Container>
			<InputField placeholder={t("general.search")} value={value} onChange={setValue} />
			{value.trim().length > 0 && (
				<Button variant="primary" onClick={() => setValue("")}>
					{t("clearFields")}
				</Button>
			)}
		</Container>
	)
}

const Container = styled.div`
	width: 100%;
	max-width: 500px;
	display: flex;
	flex-direction: row;
	gap: 8px;
	flex-wrap: wrap;
	justify-content: center;
`

export default ContentSearch

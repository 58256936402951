import { ListItemNode, ListNode } from "@lexical/list"
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin"
import { LexicalComposer } from "@lexical/react/LexicalComposer"
import { ContentEditable } from "@lexical/react/LexicalContentEditable"
import { EditorRefPlugin } from "@lexical/react/LexicalEditorRefPlugin"
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary"
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin"
import { ListPlugin } from "@lexical/react/LexicalListPlugin"
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin"
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin"
import { HeadingNode } from "@lexical/rich-text"
import { type LexicalEditor as LexicalEditorType } from "lexical"
import { useRef, type FC } from "react"

import { isEditorStateEmpty } from "../../utilities/lexical"
import LexicalToolbarPlugin from "./LexicalToolbarPlugin"

export { LexicalEditorType }

type Props = {
	editorRef(element: LexicalEditorType): void
	onEmptyChange?(isEmpty: boolean): void
	features: { headings: boolean; colors: boolean }
}
const LexicalEditor: FC<Props> = ({ editorRef, onEmptyChange, features }) => {
	const isEmptyCache = useRef(true)

	return (
		<LexicalComposer
			initialConfig={{
				namespace: "Editor",
				theme: {
					text: {
						bold: "lexical-bold",
						italic: "lexical-italic",
						underline: "lexical-underline",
						strikethrough: "lexical-strikethrough",
						underlineStrikethrough: "lexical-underline__strikethrough",
						code: "lexical-code",
					},
					list: {
						ol: "lexical-list",
						ul: "lexical-list",
					},
				},
				nodes: [ListNode, ListItemNode, HeadingNode],
				onError(error) {
					console.error(error)
				},
			}}
		>
			<RichTextPlugin
				placeholder={null}
				ErrorBoundary={LexicalErrorBoundary}
				contentEditable={<ContentEditable style={{ outline: "none" }} />}
			/>
			<EditorRefPlugin editorRef={editorRef} />
			{onEmptyChange && (
				<OnChangePlugin
					onChange={state => {
						const isEmpty = isEditorStateEmpty(state)
						if (isEmpty !== isEmptyCache.current) {
							isEmptyCache.current = isEmpty
							onEmptyChange(isEmpty)
						}
					}}
				/>
			)}
			<ClearEditorPlugin />
			<LexicalToolbarPlugin features={features} />
			<ListPlugin />
			<HistoryPlugin />
		</LexicalComposer>
	)
}

export default LexicalEditor

import { type FC } from "react"
import { useParams } from "react-router"
import styled, { css } from "styled-components"

import { parseNumber } from "@forento/shared/utilities/number"

import useConversations from "~/api/conversation"
import Layout from "~/themes/school/components/Layout"
import { PageTitle } from "~/themes/school/components/PageHeader"
import PartialLoadingPage from "~/themes/school/components/PartialLoadingPage"
import { lightTextColor } from "~/themes/school/styles"
import { useTranslation } from "~/translations"

import ConversationListItem from "./ConversationListItem"
import DetailedConversation from "./DetailedConversation"
import { fullPageCutoff } from "./constants"

const ConversationsPage: FC = () => {
	const otherProfileId = parseNumber(useParams().otherProfileId ?? "")
	const t = useTranslation()

	const conversations = useConversations(otherProfileId)

	return (
		<Layout fillHeight>
			<PageTitle>{t("community.conversation.title")}</PageTitle>
			<Container>
				<Content $hasSelectedConversation={conversations.current !== null}>
					{conversations.status === "error" ? (
						<p>{t("failedToLoad")}</p>
					) : conversations.status === "loading" ? (
						<PartialLoadingPage />
					) : (
						<Conversations>
							{conversations.list.length > 0 ? (
								[...conversations.list]
									.sort((a, b) => b.lastMessage.sendDate.getTime() - a.lastMessage.sendDate.getTime())
									.map((conversation, index) => (
										<ConversationListItem key={index} conversation={conversation} />
									))
							) : (
								<NoConversations>{t("community.conversation.none")}</NoConversations>
							)}
						</Conversations>
					)}
				</Content>
				{conversations.current != null && (
					<DetailedConversation conversation={conversations.current} reload={conversations.reload} />
				)}
			</Container>
		</Layout>
	)
}

const Container = styled.div`
	height: 100%;
	flex: 1;
	display: flex;
	flex-direction: row;
	gap: 42px;
`

const Content = styled.div<{ $hasSelectedConversation: boolean }>`
	align-self: flex-start;
	max-width: 1400px;
	flex: 1 0 0;
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 42px;

	${props =>
		props.$hasSelectedConversation &&
		css`
			@media (max-width: ${fullPageCutoff - 1}px) {
				display: none;
			}
		`}
`

const Conversations = styled.div`
	width: 100%;
`

const NoConversations = styled.p`
	color: ${lightTextColor};
`

export default ConversationsPage

import { type FC, type ReactNode } from "react"
import styled from "styled-components"

import { fonts } from "~/themes/school/styles"

type Props = {
	thumbnail?: ReactNode
	title: string
	description?: string
	htmlDescription?: string
	callToAction: ReactNode
}

const Hero: FC<Props> = ({ thumbnail, title, description, htmlDescription, callToAction }) => (
	<Container $hasThumbnail={Boolean(thumbnail)}>
		{thumbnail && <ThumbnailContainer>{thumbnail}</ThumbnailContainer>}
		<Title>{title}</Title>
		{description && <Description as="p">{description}</Description>}
		{htmlDescription && <Description dangerouslySetInnerHTML={{ __html: htmlDescription }} />}
		<CallToAction>{callToAction}</CallToAction>
	</Container>
)

const Container = styled.div<{ $hasThumbnail: boolean }>`
	background-color: white;
	box-shadow: 0px 4px 30px 0px rgba(223, 232, 255, 0.25);
	padding: 24px;
	margin-bottom: 32px;

	@media (max-width: 999px) {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 16px;
	}

	@media (min-width: 1000px) {
		padding: 64px 86px;
		display: grid;
		grid-template: ${props =>
			props.$hasThumbnail
				? "'thumbnail title callToAction' min-content 'thumbnail description callToAction' 1fr / max-content 1fr max-content"
				: "'title callToAction' 1fr 'description callToAction' min-content / 1fr max-content"};
		gap: 32px 42px;
	}
`

const Title = styled.h2`
	grid-area: title;
	font-family: ${fonts.quicksand};
	font-size: 20px;
	font-weight: bold;
`

const Description = styled.div`
	grid-area: description;
	font-size: 14px;
`

const CallToAction = styled.div`
	grid-area: callToAction;
	justify-self: flex-end;
	align-self: center;
`

const ThumbnailContainer = styled.div`
	grid-area: thumbnail;
	width: 100%;
	max-width: 400px;
	aspect-ratio: 16 / 9;

	@media (min-width: 1000px) {
		width: 300px;
	}
`

export default Hero

import { type FC, useState } from "react"
import styled from "styled-components"

import CommunityProfileAvatar from "@forento/shared/components/CommunityProfileAvatar"
import { type PersonalizedCommunityPost } from "@forento/shared/models/community"
import { toCountFormat } from "@forento/shared/utilities/number"

import { useAlert } from "~/contexts/AlertContext"
import { usePlatform } from "~/contexts/PlatformContext"
import Button from "~/themes/original/components/Button"
import { CommentIcon, DeleteIcon, LikeIcon } from "~/themes/original/components/Icon"
import MoreActions from "~/themes/original/components/MoreActions"
import PostReactions from "~/themes/original/components/PostReactions"
import ProfilePopup from "~/themes/original/components/ProfilePopup"
import { toRelativeDateString } from "~/themes/original/utilities/date"
import routes from "~/utilities/routes"
import { lightTextColor } from "~/utilities/styles"
import trpc from "~/utilities/trpc"

import Attachment from "./Attachment"
import PollOption from "./PollOption"

type Props = { post: PersonalizedCommunityPost; onChange(): void; showChannel?: boolean }
const Post: FC<Props> = ({ post, onChange, showChannel }) => {
	const platform = usePlatform().platform
	const alert = useAlert()

	const [isReactionPopupOpen, setReactionPopupOpen] = useState(false)
	const [authorElement, setAuthorElement] = useState<HTMLDivElement | null>(null)

	const handleVote = async (pollOptionId: number | null) => {
		await trpc.community.setPostVote.mutate({
			postId: post.id,
			pollOptionId:
				pollOptionId !== null && !post.pollOptions.find(x => x.id === pollOptionId)?.hasUserSelected
					? pollOptionId
					: null,
		})
		onChange()
	}

	const handleLike = async (reaction: boolean) => {
		if (reaction) {
			await trpc.community.createPostReaction.mutate(post.id)
		} else {
			await trpc.community.deletePostReaction.mutate(post.id)
		}
		onChange()
	}

	const handleDelete = async () => {
		const dialog = await alert.confirm(
			"Delete post",
			"Are you sure you want to delete this post? This action cannot be undone.",
		)
		if (!dialog.result) return
		await trpc.community.deletePost.mutate(post.id)
		dialog.close()
		onChange()
	}

	const categoryParts = []
	if (platform.content.multipleChannels && showChannel) {
		categoryParts.push(post.channel.name)
	}
	if (post.category) {
		categoryParts.push(post.category.label)
	}

	return (
		<Container>
			<ProfilePopup targetElement={authorElement} profileId={post.profile.id} />
			<PostReactions postId={isReactionPopupOpen ? post.id : null} onClose={() => setReactionPopupOpen(false)} />
			<Header>
				<Avatar profile={post.profile} ref={setAuthorElement} />
				<Author onClick={routes.community.profile(post.profile.id)}>
					{post.profile.firstName} {post.profile.lastName}
				</Author>
				<Date onClick={routes.community.post(post.id)}>{toRelativeDateString(post.createDate)}</Date>
				{post.hasEditAccess && (
					<MoreActions
						actions={[{ label: "Delete", icon: DeleteIcon, isDanger: true, onClick: handleDelete }]}
					/>
				)}
			</Header>
			{categoryParts.length > 0 && <Category>{categoryParts.join(" > ")}</Category>}
			<Button onClick={routes.community.post(post.id)}>
				<Title>{post.title}</Title>
			</Button>
			<Content dangerouslySetInnerHTML={{ __html: post.htmlContent }} />
			{post.pollOptions.length > 0 && (
				<PollOptions>
					{post.pollOptions.map((option, index) => (
						<PollOption
							key={index}
							option={option}
							onChange={isSelected => (isSelected ? handleVote(option.id) : handleVote(null))}
						/>
					))}
				</PollOptions>
			)}
			{post.attachments.length > 0 && (
				<Attachments>
					{post.attachments.map(attachment => (
						<Attachment key={attachment.id} attachment={attachment} />
					))}
				</Attachments>
			)}
			<Footer>
				{post.channel.userAccess !== null && post.channel.userAccess !== "view" ? (
					<FooterGroup>
						<LikeButton onClick={() => handleLike(!post.hasUserReacted)}>
							<FooterGroupIcon as={LikeIcon} isFilled={post.hasUserReacted} />
						</LikeButton>
						<LikeCountButton onClick={() => setReactionPopupOpen(true)}>
							{toCountFormat(post.reactionsCount)}
						</LikeCountButton>
					</FooterGroup>
				) : (
					<FooterGroup>
						<FooterGroupIcon as={LikeIcon} />
						<Button onClick={() => setReactionPopupOpen(true)}>{toCountFormat(post.reactionsCount)}</Button>
					</FooterGroup>
				)}
				<FooterGroup as={Button} onClick={routes.community.post(post.id)}>
					<FooterGroupIcon as={CommentIcon} />
					{toCountFormat(post.commentsCount)}
				</FooterGroup>
			</Footer>
		</Container>
	)
}

const Container = styled.article`
	background-color: #ffffff;
	border-radius: 8px;
	padding: 16px 24px;
`

const Header = styled.div`
	display: grid;
	grid-template-areas: "avatar name actions" "avatar date actions";
	grid-template-columns: min-content 1fr min-content;
	gap: 0 16px;
	margin-bottom: 16px;
`

const Avatar = styled(CommunityProfileAvatar)`
	grid-area: avatar;
`

const Author = styled(Button)`
	grid-area: name;
	font-weight: 600;
	font-size: 20px;
	color: #151d48;
`

const Date = styled(Button)`
	grid-area: date;
	font-size: 14px;
	color: ${lightTextColor};
`

const Category = styled.p`
	font-size: 14px;
	color: ${lightTextColor};
	margin: 0 ${32 - 24}px 4px;
`

const Title = styled.h1`
	font-weight: 500;
	font-size: 16px;
	color: #151d48;
	margin: 0 ${32 - 24}px 8px;
`

const Content = styled.div`
	font-size: 16px;
	color: #444a6d;
	margin: 0 ${32 - 24}px 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const PollOptions = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-bottom: 16px;
`

const Attachments = styled.div`
	font-size: 16px;
	color: ${lightTextColor};
	margin-bottom: 32px;
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
`

const Footer = styled.div`
	display: flex;
	align-items: center;
	gap: 42px;
	margin: 0 ${32 - 24}px;
`

const FooterGroup = styled.div`
	display: flex;
	align-items: center;
	font-size: 16px;
	color: ${lightTextColor};
	gap: 12px;
`

const LikeButton = styled(Button)`
	display: flex;
	color: ${lightTextColor};
`

const LikeCountButton = styled(Button)`
	font-size: 16px;
	color: ${lightTextColor};
`

const FooterGroupIcon = styled.div`
	width: 24px;
	height: 24px;
`

export default Post

import { useId, useMemo, useState } from "react"
import type React from "react"
import styled, { css } from "styled-components"

import LexicalEditor, { type LexicalEditorType } from "@forento/shared/components/LexicalEditor"
import { exportEditorState, loadEditorState } from "@forento/shared/utilities/lexical"

import { lightTextColor } from "~/utilities/styles"

interface InputFieldProps {
	label?: string
	value: string
	onChange?: (value: string) => void
	onEnter?: () => void
	placeholder?: string
	inputType?: "text" | "number" | "email" | "password"
	autoComplete?: "given-name" | "family-name" | "email" | "current-password" | "new-password"
	disabled?: boolean
	className?: string
}

interface InputTextAreaProps {
	label?: string
	value: string
	onChange: (value: string) => void
	placeholder?: string
	className?: string
}

const InputField: React.FC<InputFieldProps> = ({
	label,
	value,
	onChange,
	onEnter,
	placeholder,
	inputType = "text",
	autoComplete,
	disabled,
	className,
}) => {
	const inputId = useId()

	return (
		<Container className={className}>
			{label !== undefined && <Label htmlFor={inputId}>{label}</Label>}
			<Input
				id={inputId}
				type={inputType}
				value={value}
				onChange={e => onChange?.(e.target.value)}
				onKeyDown={e => e.key === "Enter" && onEnter?.()}
				placeholder={placeholder}
				autoComplete={autoComplete}
				disabled={disabled}
				data-sentry-mask={inputType === "password" ? true : undefined}
			/>
		</Container>
	)
}

export const InputTextArea: React.FC<InputTextAreaProps> = ({ label, value, onChange, placeholder, className }) => {
	const inputId = useId()

	return (
		<Container className={className}>
			{label !== undefined && <Label htmlFor={inputId}>{label}</Label>}
			<TextArea id={inputId} value={value} onChange={e => onChange(e.target.value)} placeholder={placeholder} />
		</Container>
	)
}

export function useRichTextArea({ label }: { label?: string }) {
	const id = useId()
	const [editorRef, setEditorRef] = useState<LexicalEditorType>()

	const value = useMemo(
		() => ({
			element: (
				<Container onClick={() => editorRef?.focus()}>
					{label !== undefined && <Label htmlFor={id}>{label}</Label>}
					<RichTextArea id={id}>
						<LexicalEditor editorRef={setEditorRef} features={{ colors: false, headings: false }} />
					</RichTextArea>
				</Container>
			),
			set(data: string | null): void {
				if (!editorRef) return
				loadEditorState(editorRef, data)
			},
			exportEditorState(): string | null {
				if (!editorRef) return null
				return exportEditorState(editorRef.getEditorState())
			},
		}),
		[editorRef, id, label],
	)

	return value
}

const Container = styled.div`
	width: 100%;
	min-width: 0;
	display: flex;
	flex-direction: column;
`

export const Label = styled.label`
	display: block;
	font-weight: 600;
	font-size: 16px;
	color: #151d48;
	margin-bottom: 12px;
`

const inputStyles = css`
	font-size: 16px;
	color: ${lightTextColor};
	background-color: white;
	border: 1px solid #e8e8ed;
	border-radius: 8px;
	padding: 16px;

	&::placeholder {
		color: #999;
	}

	&:disabled {
		background-color: #f0f0ef;
	}
`

const Input = styled.input`
	${inputStyles}
	line-height: 1;
`

const TextArea = styled.textarea`
	${inputStyles}
	min-height: 104px; // Twice the height of an input field
`

const RichTextArea = styled.div`
	${inputStyles}
	min-height: 104px; // Twice the height of an input field
	display: flex;
	flex-direction: column;
	cursor: text;
`

export default InputField

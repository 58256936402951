import "@editorjs/editorjs"

import "./main.css"

export default class YoutubeEmbed {
	private youtubeId: string | null

	constructor({ data }: { data: unknown }) {
		this.youtubeId = getYoutubeIdFromData(data)
	}

	render(): HTMLDivElement {
		const wrapper = document.createElement("div")

		const createIframe = (videoId: string | null) => {
			wrapper.innerHTML = ""
			const playerContainer = document.createElement("div")
			playerContainer.classList.add("video-wrapper")

			const iframe = document.createElement("iframe")
			iframe.setAttribute("src", `https://www.youtube-nocookie.com/embed/${videoId}`)
			iframe.setAttribute(
				"allow",
				"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
			)
			iframe.setAttribute("referrerpolicy", "strict-origin-when-cross-origin")
			iframe.setAttribute("allowfullscreen", "true")
			iframe.style.width = "100%"
			iframe.style.height = "auto"
			iframe.style.aspectRatio = "16 / 9"
			iframe.style.border = "none"

			playerContainer.appendChild(iframe)
			wrapper.appendChild(playerContainer)
		}

		if (this.youtubeId) {
			createIframe(this.youtubeId)
		} else {
			const input = document.createElement("input")
			input.className = "ejs-youtube-input"
			input.placeholder = "Paste YouTube video URL..."

			wrapper.classList.add("block-wrapper")
			wrapper.appendChild(input)

			input.addEventListener("change", () => {
				const result = input.value.match(/(?<=v=)[a-zA-Z0-9_-]+(?=&?)/)
				if (result) {
					this.youtubeId = result[0]
					createIframe(this.youtubeId)
				} else {
					wrapper.querySelector("input")?.classList.add("invalid")
				}
			})
		}

		return wrapper
	}

	save() {
		return { youtubeId: this.youtubeId }
	}

	validate(data: unknown): boolean {
		return getYoutubeIdFromData(data) !== null
	}

	static get toolbox(): { icon: string; title: string } {
		return {
			title: "YouTube",
			icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;"><path d="M21.593 7.203a2.506 2.506 0 0 0-1.762-1.766C18.265 5.007 12 5 12 5s-6.264-.007-7.831.404a2.56 2.56 0 0 0-1.766 1.778c-.413 1.566-.417 4.814-.417 4.814s-.004 3.264.406 4.814c.23.857.905 1.534 1.763 1.765 1.582.43 7.83.437 7.83.437s6.265.007 7.831-.403a2.515 2.515 0 0 0 1.767-1.763c.414-1.565.417-4.812.417-4.812s.02-3.265-.407-4.831zM9.996 15.005l.005-6 5.207 3.005-5.212 2.995z"></path></svg>',
		}
	}

	static get isReadOnlySupported(): boolean {
		return true
	}
}

function getYoutubeIdFromData(data: unknown): string | null {
	return typeof data === "object" &&
		data !== null &&
		"youtubeId" in data &&
		typeof data.youtubeId === "string" &&
		data.youtubeId.trim().length > 0
		? data.youtubeId.trim()
		: null
}
